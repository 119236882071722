import { writable, type Readable } from 'svelte/store'

type SherpaStore = {
  isLoading: boolean
}

const store = writable<SherpaStore>({ isLoading: false })

function setLoading(isLoading: boolean) {
  store.update((state) => ({ ...state, isLoading }))
}

const sherpa: Readable<SherpaStore> & { setLoading: typeof setLoading } = {
  subscribe: store.subscribe,
  setLoading,
}

export default sherpa
